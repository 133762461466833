const GOOGLE_MAPS_API_ACCESS_TOKEN = process.env.REACT_APP_GOOGLE_API_ACCESS_TOKEN;
const GOOGLE_MAPS_TIMEZONE_API_ACCESS_TOKEN =
  process.env.REACT_APP_GOOGLE_TIMEZONE_API_ACCESS_TOKEN;
const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
// eslint-disable-next-line no-unused-vars
const APP_API_SMS = process.env.REACT_APP_API_SMS;
const API_ROOT_URL_V2 = process.env.REACT_APP_API_ROOT_URL_V2;

//*****************************************************************************************************************
//                                GOOGLE MAPS GEOCODING LOCATION API                                             //
//*****************************************************************************************************************

async function getCurrentPositionUsingGoogleMapsAPI() {
  console.log('Current position using Google Maps API');
  const URL = `https://www.googleapis.com/geolocation/v1/geolocate?key=${getGoogleMapsAPIAccessToken()}`;
  const response = await fetch(URL, {
    method: 'POST',
  });

  const response_check = await checkStatus(response);
  const json_data = await parseJSON(response_check);

  return json_data;
}

const getCurrentPositionUsingNativeGeolocator = (options) => {
  console.log('Current position using Native Geolocator');
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  })
    .then((position) => {
      const coordinates = {
        location: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        accuracy: position.coords.accuracy,
      };
      return coordinates;
    })
    .catch((error) => {
      return {
        error: error,
      };
    });
};

const getAddressUsingCoordinates = ({ lat, lng }) => {
  console.log('Geocoding - getting address through coordinates');
  const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_ACCESS_TOKEN}`;
  return fetch(URL, {
    method: 'GET',
  })
    .then(checkStatus)
    .then(parseJSON);
};

const getAddressUsingZIP = (zipCode) => {
  console.log('Geocoding - getting address using ZIP');
  const URL = `https://maps.googleapis.com/maps/api/geocode/json?address=ZIP+${zipCode.padStart(5, '0')},USA&key=${GOOGLE_MAPS_API_ACCESS_TOKEN}`;
  return fetch(URL, {
    method: 'GET',
  })
    .then(checkStatus)
    .then(parseJSON);
};

const isZIPValid = async (zip) => {
  console.log('Checkign if zip is valid');
  const data = await getAddressUsingZIP(zip);

  if (!data) return false;

  if (data.results.length > 0) {
    return data.results[0].types && data.results[0].types[0] === 'postal_code';
  }

  return false;
};

//*****************************************************************************************************************
//                                  GOOGLE MAP TIMEZONE API CALLS                                                //
//*****************************************************************************************************************

const getTimeByLocation = (location, timestamp) => {
  console.log('Timezone API - getting time by location');
  const URL =
    'https://maps.googleapis.com/maps/api/timezone/json?' +
    `location=${location ? location.lat : 15.7},${location ? location.lng : 121}` +
    `&timestamp=${timestamp}` +
    `&key=${getGoogleMapsTimezoneAPIAccessToken()}`;

  return fetch(URL, {})
    .then(parseResponse)
    .then(parseJSON)
    .catch((e) => {
      return {
        error: e,
      };
    });
};

//*****************************************************************************************************************
//                                UTILITY FUNCTIONS FOR API RESPONSES                                            //
//*****************************************************************************************************************

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    throw new Error('API ERROR: ' + response.statusText);
  }
};

const parseJSON = (response) => {
  return response.json();
};

const catchGenericError = (error) => {
  return {
    error: error,
  };
};

const parseResponse = (response) => {
  console.log(response.status);
  return response;
};

const aborted = (error) => {
  // if(error.name === 'AbortError') {
  //     console.log("Request aborted.");
  // }

  // console.log(error.name);
  return null;
};

//*****************************************************************************************************************
//                                              TOKEN GETTERS                                                    //
//*****************************************************************************************************************

const getGoogleMapsAPIAccessToken = () => {
  return GOOGLE_MAPS_API_ACCESS_TOKEN;
};

const getGoogleMapsTimezoneAPIAccessToken = () => {
  return GOOGLE_MAPS_TIMEZONE_API_ACCESS_TOKEN;
};

//*****************************************************************************************************************
//                                              API CALLS                                                        //
//*****************************************************************************************************************

const getClinicsFromZIP = (
  { zip, long, lat, radius, covidTest, antibodyTest, xray },
  abortController = null
) => {
  const zipFilter = zip !== '' ? '&zip=' + zip.replace(/^0+/, '') : '';
  const partnership = '&partnership=1';
  const URL = API_ROOT_URL + '?' + zipFilter + partnership;

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (abortController) options['signal'] = abortController.signal;

  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

const searchClinicsForInvite = (fields) => {
  console.log(fields);
  const URL = API_ROOT_URL_V2 + 'invite/';
  console.log(URL);

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };

  return fetch(URL, options)
    .then(checkStatus)
    .then(parseJSON)
    .catch((e) => {
      return {
        error: e,
      };
    });
};

const getClinicsNearUserLocation = (
  { zip, long, lat, radius, covidTest, antibodyTest, xray },
  abortController = null
) => {
  const latitudeFilter = lat ? '&lat=' + lat : '';
  const longitudeFilter = long ? '&long=' + long : '';
  const partnership = '&partnership=1';
  const URL =
    API_ROOT_URL + '?' + latitudeFilter + longitudeFilter + '&radius=' + radius + partnership;

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (abortController) options['signal'] = abortController.signal;

  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

const getClinicsNearZIPLocation = async (
  { zip, long, lat, radius, covidTest, antibodyTest, xray },
  abortController = null
) => {
  const latitudeFilter = lat ? '&lat=' + lat : '';
  const longitudeFilter = long ? '&long=' + long : '';
  const partnership = '&partnership=1';
  const URL =
    API_ROOT_URL + '?' + latitudeFilter + longitudeFilter + '&radius=' + radius + partnership;

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (abortController) options['signal'] = abortController.signal;

  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

const getClinicsNearZIPLocationV2 = ({ lat, long, radius }, abortController = null) => {
  const URL = API_ROOT_URL_V2 + `practice/search/?lat=${lat}&lng=${long}&radius=${radius}`;
  console.log('V2: ' + URL);

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (abortController) options['signal'] = abortController.signal;

  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

const getClinicsNearLocationV2 = ({ lat, long }, abortController = null) => {
  const URL = API_ROOT_URL_V2 + `practice/search/full/?lat=${lat}&lng=${long}`;
  console.log('V2: ' + URL);

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (abortController) options['signal'] = abortController.signal;

  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

const getClinicsNearZIPLocationV3 = ({ lat, long, radius }, abortController = null) => {
  const endpoint = getNodeApiEndpoint();
  const URL = `${endpoint}/api/v2/practice/search/?lat=${lat}&lng=${long}&radius=${radius}`;
  console.log('V3: ' + URL);

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

const getClinicsNearLocationV3 = ({ lat, long }, abortController = null) => {
  const endpoint = getNodeApiEndpoint();
  const URL = `${endpoint}/api/v2/practice/search/full/?lat=${lat}&lng=${long}`;
  console.log('V3: ' + URL);
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

const getPractice = (id, abortController = null) => {
  const partnership = '?&partnership=1';
  const URL = API_ROOT_URL + id + partnership;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (abortController) options['signal'] = abortController.signal;
  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

const getPracticeV2 = (practice_id, abortController = null) => {
  const URL = API_ROOT_URL_V2 + 'practice/?practice_id=' + practice_id;
  console.log(URL);
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (abortController) options['signal'] = abortController.signal;

  return fetch(URL, options)
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      return {
        error: error,
      };
    });
};

/** Node API Backend **/
const getNodeApiEndpoint = () => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return 'http://localhost:43000';
    // return 'https://apiv2.urgentcare.net';
  } else if (hostname === 'staging.urgentcare.com') {
    return 'https://apiv2staging.urgentcare.net';
  } else {
    return 'https://apiv2.urgentcare.net';
  }
};

const getPracticeV3 = (practice_id, abortController = null) => {
  const endpoint = getNodeApiEndpoint();
  const URL = `${endpoint}/api/v2/practice/?practice_id=${practice_id}`;
  console.log(URL);
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return fetch(URL, options)
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      return {
        error: error,
      };
    });
};

/** End Node API Backend **/
const handleIframeMessage = (event) => {
  // Get full current url
  const currentUrl = window.location.href;

  try {
    if (event.data && typeof event.data === 'string' && event.data.indexOf('[iFrame') !== -1) {
      return;
    }
    if (event.data && event.data.source && event.data.source.startsWith('react')) {
      return;
    }
    console.log(event.data);
    // if (event.data.email) {
    //   // Send to Slack webhook
    //   postToSlack(`New Semaglutide lead on Urgentcare.com: ${event.data.email}`);
    //   postToSlack(JSON.stringify(event.data));
    // }
    console.log(`New message from ${currentUrl}: ${JSON.stringify(event.data)}`);
    postToSlack(`New message from ${currentUrl}: ${JSON.stringify(event.data)}`);
  } catch (error) {
    console.error('Error handling message:', error);
  }
};

const postToSlack = async (message) => {
  const endpoint = getNodeApiEndpoint();

  try {
    await fetch(`${endpoint}/api/v2/mwl/notify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    });
  } catch (error) {
    console.error('Error posting to Slack:', error);
  }
};

const getNearestClinics = (
  { zip, long, lat, radius, covidTest, antibodyTest, xray },
  abortController = null
) => {
  const latitudeFilter = '&lat=' + lat;
  const longitudeFilter = '&lng=' + long;
  const partnership = '&partnership=1';
  const URL = API_ROOT_URL + 'nearest/?' + latitudeFilter + longitudeFilter + partnership;

  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (abortController) options['signal'] = abortController.signal;

  return fetch(URL, options).then(checkStatus).then(parseJSON).catch(aborted);
};

// distance

const getDistanceBetweenTwoLocations = (from, to) => {
  const URL =
    API_ROOT_URL +
    'distance?' +
    `fromLat=${from.lat}` +
    `&fromLng=${from.lng}` +
    `&toLat=${to.lat}` +
    `&toLng=${to.lng}`;
  return fetch(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(parseJSON);
};

const getDistanceBetweenTwoLocationsV2 = (from, to) => {
  const URL =
    API_ROOT_URL_V2 +
    `practice/distance/?from_lat=${from.lat}&from_lng=${from.lng}&to_lat=${to.lat}&to_lng=${to.lng}`;
  console.log(URL);

  return fetch(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((e) => {
      return {
        error: e,
      };
    });
};

//*****************************************************************************************************************
//                                                  SMS API                                                      //
//*****************************************************************************************************************
const send_sms = (to_number, slug) => {
  const URL = API_ROOT_URL_V2 + 'sms/';
  // const URL = "http://localhost:8003/api/sms/"
  // console.log("Sending to: " + URL);
  // console.log(to_number);
  console.log(URL);
  return fetch(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to: to_number,
      link: slug,
      site: 'www.urgentcare.com',
    }),
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      console.log(error);
      return null;
    });
};

//*****************************************************************************************************************
//                                              CAMPAIGNS API                                                    //
//*****************************************************************************************************************

const getCampaign = (code) => {
  const URL = API_ROOT_URL_V2 + `campaigns/?code=${code}`;
  console.log(URL);
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return fetch(URL, options).then(parseResponse).then(parseJSON).catch(catchGenericError);
};

//*****************************************************************************************************************
//                                             PRACTICE SET API                                                  //
//*****************************************************************************************************************
const getPracticeSet = (set) => {
  const URL = API_ROOT_URL_V2 + `practice/set/?practices=${set.join(',')}`;
  console.log(URL);
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return fetch(URL, options).then(parseResponse).then(parseJSON).catch(catchGenericError);
};

export {
  getCurrentPositionUsingGoogleMapsAPI,
  getGoogleMapsAPIAccessToken,
  getAddressUsingCoordinates,
  getAddressUsingZIP,
  getClinicsFromZIP,
  getClinicsNearUserLocation,
  getDistanceBetweenTwoLocations,
  getTimeByLocation,
  getNearestClinics,
  getPractice,
  getClinicsNearZIPLocation,
  send_sms,
  getCurrentPositionUsingNativeGeolocator,
  isZIPValid,
  getPracticeV2,
  getPracticeV3,
  getClinicsNearZIPLocationV2,
  getClinicsNearZIPLocationV3,
  getDistanceBetweenTwoLocationsV2,
  searchClinicsForInvite,
  getClinicsNearLocationV2,
  getClinicsNearLocationV3,
  getCampaign,
  getPracticeSet,
  handleIframeMessage,
  postToSlack,
};
